import { PrivacyControllerService, PrivacyVo } from '@/__generated__/FrontApi';
import Container from '@/components/Container';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { PrivacyContainer } from '@/components/Term';
import { Tit } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PrivacyData } from '@/pages/privacy';
import { PageProps, Link } from 'gatsby';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface CorpProps {
  indexNumber: number;
  version: string;
  enforceDate: string;
  companyName: string;
  content: string;
  content2: string;
  departmentName: string;
  managerName: string;
  setDateYn: string;
  useYn: string;
  firstRegisterDate: string;
  lastModifyDate: string;
  startDate: string;
  endDate: string;
}

const PrivacyCorp: FC<PageProps> = ({ location }) => {
  const [latestPrivacy, setLatestPrivacy] = useState<PrivacyData[]>([]);
  const [corp, setCorp] = useState<CorpProps[]>([]);

  // 최신 버전 로드
  const loadLatestPrivacy = useCallback(async () => {
    try {
      const {
        data: privacy,
      } = await PrivacyControllerService.getPrivacyListUsingGet();
      setLatestPrivacy(
        privacy
          .filter((last: PrivacyVo) => last.latestVersion === 'Y')
          .map((last: PrivacyVo) => ({
            id: last.indexNumber,
            version: last.version,
            enforceDate: last.enforceDate,
            content: last.content,
            useYn: last.useYn,
            latestVersion: last.latestVersion,
          })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 위탁업체 목록 로드
  const loadCorp = useCallback(async () => {
    try {
      const {
        data: privacyCorp,
      } = await PrivacyControllerService.getPrivacyCorpListUsingGet();
      setCorp(
        privacyCorp
          .map((co: PrivacyVo) => ({
            id: co.indexNumber,
            version: co.version,
            enforceDate: co.enforceDate,
            content: co.content,
            content2: co.content2,
            companyName: co.companyName,
            useYn: co.useYn,
          }))
          .sort((a, b) => b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadLatestPrivacy();
    loadCorp();
  }, [loadLatestPrivacy, loadCorp]);

  const currentKey = 1;

  return (
    <LayoutWithTitle location={location} title="개인정보 처리방침">
      <Section className="by-sub-main-layout">
        <Container>
          {latestPrivacy.map((row) => (
            <SectionHeader className="with-desc">
              <h2>
                <Tit size="s1">개인정보 처리방침 v{row.version}</Tit>
              </h2>
              <Tit size="s3">(시행일 {row.enforceDate})</Tit>
            </SectionHeader>
          ))}

          <Tit size="s1-5" color="sky" weight="normal" id="tab-section">
            <PageComponent id="title1">
              유니세프한국위원회는 개인정보를 소중히 다루고 있습니다
            </PageComponent>
          </Tit>

          <PrivacyContainer>
            <div className="tab-btn">
              <Swiper
                slidesPerView={3}
                navigation={false}
                scrollbar={{ draggable: false }}
                noSwiping
                allowTouchMove={false}
                breakpoints={{
                  641: {
                    slidesPerView: 3,
                    initialSlide: currentKey,
                  },
                  320: {
                    allowTouchMove: true,
                    slidesPerView: 2.5,
                    initialSlide: currentKey,
                  },
                }}
              >
                <SwiperSlide>
                  <Link to="/privacy#tab-section" className="link-item">
                    개인정보 처리방침
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link
                    to="/privacy-corp#tab-section"
                    className="link-item _active"
                  >
                    위탁업체
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/cctv-policy#tab-section" className="link-item">
                    영상정보처리기기 <br />
                    운영 · 관리 방침
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>

            <article>
              <strong>위탁업체 현황</strong>
              <p>
                원활한 업무 처리를 위해 이용자의 개인정보를 위탁 처리할 경우
                반드시 사전에 개인정보 처리 위탁을 받는 자(이하 ‘수탁자’라
                합니다)와 개인정보 처리위탁을 하는 업무의 내용을 고지합니다.
                현재 유니세프한국위원회의 개인정보처리 수탁자와 그 업무의 내용은
                다음과 같습니다.
              </p>

              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>위탁대상자(수탁자)</th>
                    <th>위탁업무 내용(수탁업무)</th>
                    <th>개인정보의 보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  {corp.map((row) => (
                    <tr>
                      <td>{row.companyName}</td>
                      <td>{row.content2}</td>
                      <td>{row.content}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </article>
          </PrivacyContainer>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default PrivacyCorp;
